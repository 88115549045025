import dayjs from "dayjs";

const curh=window.location.hostname
let D_URL = process.env.REACT_APP_D_URL;
// let D_URL = `http://${curh}:20086`;
// if(curh=='localhost'||curh=='prognoz.karantin.uz'){
//     // D_URL = "https://efield.efito.uz";
// }

export const API_URL=D_URL;
// export const API_URL = "localhost:8000";
export const BASE_URL = `${D_URL}/api/`;
export const AUTH_BASE_URL = `${D_URL}/`;
export const CURRENCY = "UZS";
export const SERVER_DATE_FORMAT = "YYYY-MM-DD hh:mm:ss";
export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_FORMAT_FULL = "DD.MM.YYYY hh:mm:ss";
export const DASHBOARD_FILTER_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_START_DATE = dayjs(new Date()).startOf("M").format(DASHBOARD_FILTER_DATE_FORMAT);
export const DEFAULT_END_DATE = dayjs(new Date()).endOf("M").format(DASHBOARD_FILTER_DATE_FORMAT);
