export const makeAPI = (url) => {
    return `${process.env.REACT_APP_D_URL}/api/` + url
    // const curh=window.location.hostname
    // if(curh=='localhost'||curh=='efield.efito.uz'){
    //   return `${process.env.REACT_APP_D_URL}/api/` + url
    // }else{
    //   return `http://${curh}:20086/api/` + url
    // }

};

// export const makeAPI = (url) => ('http://192.168.145.246:30081/api/' + url);

export function handleErrors(response) {
    if (!response.ok) {
        console.log("KELDI", response)
        alert('Xato!')
        // throw Error(response.statusText);
    }
    return response;
}

